import React, {useState, useContext} from 'react';
import MenuData from '../data/menu.json';
import {LanguageContext} from '../context'
import {MainContent} from './MainContent/MainContent.js'
import ScrollIntoView from 'react-scroll-into-view'


export function Menu_visual(props) {

  const [viewCategory, setViewCategory] = useState("");

	const language = useContext(LanguageContext);

  let items = {};

  let pivot = MenuData[0].category_gr;

  if (language === "en") {
    pivot = MenuData[0].category_en;
  } else if (language === "cn") {
    pivot = MenuData[0].category_cn;
  }

  items[pivot] = [];
  items[pivot].push(MenuData[0]);


  for (let i = 1; i < MenuData.length; i++) {
    let category = MenuData[i].category_gr

    if (language === "en") {
      category = MenuData[i].category_en;
    } else if (language === "cn") {
      category = MenuData[i].category_cn;
    }

    if (pivot === category) {
      items[pivot].push(MenuData[i]);
    } else {
      items[category] = [];
      items[category].push(MenuData[i]);
      pivot = category;
    }
  };


  let headerLinks = [];
  for (let key in items) {
    headerLinks.push(
      <ScrollIntoView selector={"#"+key.replaceAll(" ", "_")} className="section" smooth={true} style={(viewCategory === key)?{"backgroundColor": "red"} :{}}>
        <span className="nav-link" style={{cursor: "pointer", paddingLeft: "10px", color: "white"}}>{key}</span>
      </ScrollIntoView>
    );
  }


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-3" style={{"paddingLeft": "0px", "paddingRight": "10px", "backgroundColor": "#3A454B"}}>
          <div className="nav flex-column sticky-top navbar-dark" style={{"paddingTop":"55px", "zIndex": "1"}} >
            <br></br>
            <h5 style={{"paddingLeft": "10px", "paddingTop": "10px", "color": "white"}}>{(language === "en")?"Category":(language === "cn")?"类别 ":"Είδος"}</h5>
            {headerLinks}
          </div>
        </div>
        <MainContent items={items} onViewedCategoryChange={setViewCategory} viewCategory={viewCategory}/>
      </div>
    </div>
  );
}
