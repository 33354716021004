import {Link} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Button from 'react-bootstrap/Button'
import { Formik, Form, Field, ErrorMessage} from 'formik';
import DatePicker from "react-datepicker";
import * as Yup from 'yup';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import FA from 'react-fontawesome'

function BookTable(props) {
    //let reservationApi = "http://localhost:5000/api/create/reservation";
    let reservationApi = "https://beihai.gr/api/create/reservation";

    let latestTime = "18:00";
    let startTime = "19:15"
    let endTime = "23:45"
    let defaultTime = "20:00"

    const [attempted, setAttempted] = useState(false);
    const [success, setSuccess] = useState(false);
    const [btime, setBtime] = useState(defaultTime);
    const [bookedValues, setBookedValues] = useState({});
    const [passedDeadline, setPassedDeadline] = useState(false);
    const [clickedSubmit, setClickedSubmit] = useState(false);

    let today = new Date()
    let yesterday = new Date()
    yesterday.setDate(today.getDate() - 1);

    let next_week_date = new Date()
    next_week_date.setDate(today.getDate() + 7);

    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)

    let dd = String(tomorrow.getDate()).padStart(2, '0');
    let mm = String(tomorrow.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = tomorrow.getFullYear();
    //alert(tomorrow)
    let tomorrow_date = yyyy + '-' + mm + '-' + dd;

    dd = String(today.getDate()).padStart(2, '0');
    mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    yyyy = today.getFullYear();
    let today_date = yyyy + '-' + mm + '-' + dd;

    let reset = function() {
      props.handleClose();
      setSuccess(false);
      setBtime(defaultTime);
      setPassedDeadline(false);
      setClickedSubmit(false);
      setAttempted(false);
      setBookedValues({});
    };


    let submitBooking = async function(values) {

      let booked_date = new Date(values['date']);
      let selected_hour = values['time'].split(":")[0];
      let selected_min = values['time'].split(":")[1];
      booked_date.setHours(selected_hour, selected_min, 0);

      let current_date = new Date();
      let today_deadline_date = new Date(current_date);
      let latest_hour = latestTime.split(":")[0];
      let latest_min = latestTime.split(":")[1];
      today_deadline_date.setHours(latest_hour,latest_min, 0);

      if (current_date.getDate() === booked_date.getDate() && current_date.getMonth() === booked_date.getMonth() && current_date.getFullYear() === booked_date.getFullYear()) {
        if(current_date.getHours() >= latest_hour){
          setPassedDeadline(true);
          setAttempted(true);
          return
        }
      }

      if (passedDeadline === false) {
        await axios.post(reservationApi,
          values
        ).then((response)=> {
          setSuccess(true);
          setBookedValues(values);
        })
        .catch(function (error) {
          setSuccess(false);
          setBookedValues({});
        });
      }

      setAttempted(true);
    }

    let checkBookingHour = function(value) {
      if (value === undefined) {
        value = "00:00";
      }
      let hour = value.split(":")[0]
      let min = value.split(":")[1]

      var currentD = new Date();
      currentD.setHours(hour,min,0);

      var startHourD = new Date();
      const shour = startTime.split(":")[0]
      startHourD.setHours(shour,0,0);
      var endHourD = new Date();
      const ehour = endTime.split(":")[0]
      endHourD.setHours(ehour,59,0);

      let duringOpeningHours = false;
      if(currentD >= startHourD && currentD < endHourD ){
          duringOpeningHours = true;
      }else{
          duringOpeningHours = false;
      }
      return duringOpeningHours;
    }

    let handleTimeChange = function (e) {
      let hour = e.target.value.split(":")[0];
      let mins = e.target.value.split(":")[1];

      let m = (Math.round(mins/15) * 15) % 60;
      m = m < 10 ? '0' + m : m;

      if (mins > 52) {
        const ehour = endTime.split(":")[0]
        if (hour === ehour) {
          m = "45";
        }
      }
      setBtime(hour + ":" + m);
    };

    let bookButton_gr;
    
    if (clickedSubmit) {
      bookButton_gr = (
        <Button disabled variant="success" size="lg" type="submit" ><FA name="yin-yang" spin/> Αποστολή Κράτησης...</Button>
      )
    } else {
      bookButton_gr = (
        <Button variant="success" size="lg" type="submit" onClick={() => setClickedSubmit(true)}>Κράτηση</Button>
      );
    }

    let form_gr = (
      <Formik
          enableReinitialize
          initialValues={{
            date: today_date,
            time: btime,
            people: 4,
            note: '',
            name: '',
            phone: ''
          }}
          validationSchema={Yup.object({
            date: Yup.date().min(yesterday, "Η ημερομηνία πρέπει να είναι μεταγενέστερη από την χθεσινή, " + yesterday.toLocaleDateString()).max(next_week_date, "Παρακαλώ επιλέξτε κοντίνότερη ημερομηνία.").required('Υποχρεωτικό πεδίο'),
            time: Yup.string().required('Υποχρεωτικό πεδίο').test(
            "start_time_test",
            "Η κράτηση σας πρέπει να είναι μεταξύ " + startTime + " - " + endTime + ".",
            function(value) {
              return checkBookingHour(value);
            }),
            note: Yup.string(),
            people: Yup.number().min(1).max(10).required('Υποχρεωτικό πεδίο'),
            name: Yup.string().required('Υποχρεωτικό πεδίο'),
            phone: Yup.string().required('Υποχρεωτικό πεδίο'),
          })}
          onSubmit={async (values) => {
            submitBooking(values);
          }}>

          <Form>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="date">Ημερομηνία</label>
                    <Field id="date" name="date" type="date" />
                    <ErrorMessage name="date" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="time">Ώρα <i>({startTime} - {endTime})</i></label>
                    <Field id="time" name="time" type="time" onBlur={handleTimeChange}/>
                    <ErrorMessage name="time" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="people">Άτομα</label>
                    <Field id="people" name="people" type="people" as="select">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="9">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </Field>
                    <ErrorMessage name="people" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="note">Σημείωση</label>
                    <Field id="note" name="note" type="note">
                    </Field>
                    <ErrorMessage name="note" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="time">Όνομα κράτησης</label>
                    <Field id="name" name="name" />
                    <ErrorMessage name="name" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="phone">Τηλέφωνο επικοινωνίας</label>
                    <Field id="phone" name="phone" />
                    <ErrorMessage name="phone" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div className="d-grid gap-2" style={{"paddingTop":"20px"}}>
                {bookButton_gr}
                <Button variant="secondary" disabled={clickedSubmit} size="lg" onClick={reset}>Ακύρωση</Button>
              </div>
            </div>

            <Modal.Footer>

            </Modal.Footer>
          </Form>
      </Formik>
    );

    let booking_content_gr = form_gr;

    if (attempted && success) {
      booking_content_gr = (
        <div>
        <div class="alert alert-success">
          <p>Η κράτηση σας <strong>κατοχυρώθηκε</strong>. Η κράτηση είναι για {bookedValues["people"]} άτομα, μέρα {new Date(bookedValues["date"]).toLocaleDateString()}, ώρα {bookedValues["time"]}, στο όνομα {bookedValues["name"]}.
          Το τραπέζι σας θα κρατηθεί για την άφιξη σας έως και 30 λεπτά απο τις {bookedValues["time"]}.</p>
          <br></br>
          <p>Για οποιαδήποτε διευκρίνιση ή ακύρωση καλέστε μας στο <a href="tel:2721093470" class="alert-link">27210 93470</a>. </p>
        </div>
        <Modal.Footer>
          <Button variant="success" onClick={reset}>
            ΟΚ
          </Button>
        </Modal.Footer>
        </div>
      );
    } else if (attempted && !success) {
      booking_content_gr = (
        <div>
        <div class="alert alert-danger">
          <p>Παρακαλώ καλέστε στο κατάστημα ( τηλ. <a href="tel:2721093470" class="alert-link">27210 93470</a> ) για να ολοκληρωθεί η κράτηση.</p>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={reset}>
            ΟΚ
          </Button>
        </Modal.Footer>
        </div>
      )
    }

    if (passedDeadline) {
      booking_content_gr = (
        <div>
        <div class="alert alert-danger">
          <p>Η κράτηση σας ηλεκτρονικά πρέπει να γίνεται πριν τις {latestTime} της ίδιας μέρας.
          Παρακαλώ καλέστε στο κατάστημα ( τηλ. <a href="tel:2721093470" class="alert-link">27210 93470</a> ) για να ολοκληρωθεί η κράτηση.
          </p>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={reset}>
            ΟΚ
          </Button>
        </Modal.Footer>
        </div>
      )
    }

    var modal_book_gr = (
      <Modal
        show={props.show}
        onHide={reset}
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title>Ηλεκτρονική Κράτηση</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src="img/intro/table.jpg" class="img-fluid rounded mx-auto d-block" ></img>
          <hr/>
          {booking_content_gr}
        </Modal.Body>

      </Modal>
    );


    let bookButton_en;
        
    if (clickedSubmit) {
      bookButton_en = (
        <Button disabled variant="success" size="lg" type="submit" ><FA name="yin-yang" spin/> Processing...</Button>
      )
    } else {
      bookButton_en = (
        <Button variant="success" size="lg" type="submit" onClick={() => setClickedSubmit(true)}>Reserve</Button>
      );
    }

    let form_en = (
      <Formik
          enableReinitialize
          initialValues={{
            date: today_date,
            time: btime,
            people: 4,
            note: '',
            name: '',
            phone: ''
          }}
          validationSchema={Yup.object({
            date: Yup.date().min(yesterday, "The selected date cannot be before today's date, " + yesterday.toLocaleDateString()).max(next_week_date, "Please select a closer date.").required('Required'),
            time: Yup.string().required('Required').test(
            "start_time_test",
            "Your reservation time must be within opening hours " + startTime + " - " + endTime + ".",
            function(value) {
              return checkBookingHour(value);
            }),
            people: Yup.number().min(1).max(10).required('Required'),
            note: Yup.string(),
            name: Yup.string().required('Required for reference.'),
            phone: Yup.string().required('We require your phone number to call you back if required.'),
          })}
          onSubmit={async (values) => {
            submitBooking(values);
          }}
        >
          <Form>

            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="date">Date</label>
                    <Field id="date" name="date" type="date" />
                    <ErrorMessage name="date" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="time">Time <i>({startTime} - {endTime})</i></label>
                    <Field id="time" name="time" type="time" onBlur={handleTimeChange}/>
                    <ErrorMessage name="time" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="people">Persons</label>
                    <Field id="people" name="people" type="people" as="select">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="9">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </Field>
                    <ErrorMessage name="people" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="note">Note</label>
                    <Field id="note" name="note" type="note" />
                    <ErrorMessage name="note" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="time">Name</label>
                    <Field id="name" name="name" />
                    <ErrorMessage name="name" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <div class="d-flex flex-column">
                    <label htmlFor="phone">Phone Number</label>
                    <Field id="phone" name="phone" />
                    <ErrorMessage name="phone" render={msg => <medium class="form-text text-danger">{msg}</medium>} />
                  </div>
                </div>
              </div>
            </div>


            <div class="row">
              <div className="d-grid gap-2" style={{"paddingTop":"20px"}}>
                {bookButton_en}
                <Button variant="secondary" disabled={clickedSubmit} size="lg" onClick={reset}>Close</Button>
              </div>
            </div>

            <Modal.Footer>
            </Modal.Footer>
          </Form>
      </Formik>
    );

    let booking_content_en = form_en;

    if (attempted && success) {
      booking_content_en = (
        <div>
        <div class="alert alert-success">
          <p>Your reservation has been <strong>received & confirmed</strong>. The table for {bookedValues["people"]} is reserved for {bookedValues["name"]}, {new Date(bookedValues["date"]).toLocaleDateString()}, {bookedValues["time"]}.
          Your table will be reserved for your arrival for 30 minutes starting from {bookedValues["time"]}.
          </p>
          <br></br>
          <p>If you have special requirements or you'd like to cancel the booking, please call us ( tel. <a href="tel:2721093470" class="alert-link">27210 93470</a> ). </p>
        </div>
        <Modal.Footer>
          <Button variant="success" onClick={reset}>
            ΟΚ
          </Button>
        </Modal.Footer>
        </div>
      );
    } else if (attempted && !success) {
      booking_content_en = (
        <div>
        <div class="alert alert-danger">
          <p>Your reservation was unsuccessful, please call us ( tel. <a href="tel:2721093470" class="alert-link">27210 93470</a> ) to complete your booking.</p>
        </div>

        <Modal.Footer>
          <Button variant="secondary" onClick={reset}>
            ΟΚ
          </Button>
        </Modal.Footer>
        </div>
      )
    }

    if (passedDeadline) {
      booking_content_en = (
        <div>
        <div class="alert alert-danger">
          <p>Your reservation was <strong>unsuccessful</strong>. Your online reservation should be completed before <strong>{latestTime}</strong> of the same day.
           Please call us ( tel. <a href="tel:2721093470" class="alert-link">27210 93470</a> ) to confirm table availability for today.</p>
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={reset}>
            ΟΚ
          </Button>
        </Modal.Footer>
        </div>
      )
    }

    var modal_book_en = (
      <Modal
        show={props.show}
        onHide={reset}
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title>Online Reservation</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          <img src="img/intro/table.jpg" class="img-fluid rounded mx-auto d-block" ></img>
          <hr/>
          {booking_content_en}
        </Modal.Body>
      </Modal>
    );

    if (props.language == "gr") {
      return modal_book_gr;
    }
    return modal_book_en;
}

export class Intro extends React.Component {
  constructor(props) {
    super(props)

    this.handleClose = this.handleClose.bind(this);

    this.state = {show: false};

  }

  handleClose() {
    this.setState({show: false})
  }

  render () {
    const sMediaStyle = {'textDecoration': 'none', 'fontSize': '24px', "color": 'white'};
    var background = {"color": "#FFF", "minHeight": "100vh"};

    var intro_gr = (
      <div id="intro" className="container-fluid">
      <div className="container" style={background}>
      <p className="text-center"><img src="img/intro/logo.png"></img></p>
      <h1 className="text-center"> Καλώς ορίσατε </h1>
      <h3 className="text-center">Το κινέζικο της Καλαμάτας από το 1991.</h3>
      <p className="text-center">Ελάτε να γνωρίσετε από κοντά τις ιδιαίτερες γεύσεις της Κίνας μέσα από ένα
        ταξίδι αισθήσεων. Μία μαγευτική διαδρομή στον πολιτισμό και στην φιλοσοφία μίας από τις
        αρχαιότερες χώρες του κόσμου μέσα από τα πιάτα της.</p>
      <p className="text-center"> Τηλέφωνο: <a href="tel:2721093470" style={{'color': 'white'}}>27210 93470</a> | Δευ-Κυρ: 19:15 - 00:30 | <a href="https://g.page/beihai-restaurant?share" style={{"color": "white"}}>Ακρίτα 131</a>, Καλαμάτα</p>

      <p className="text-center">
        <a className="fab fa-facebook-square" ariaHidden="true" style={sMediaStyle} href="https://www.facebook.com/beihai.restaurant/"></a>&nbsp;
        <a className="fab fa-instagram-square" style={sMediaStyle} href="https://www.instagram.com/beihaigr/"></a>&nbsp;
        <a className="fab fa-tripadvisor" href="https://www.tripadvisor.co.uk/Restaurant_Review-g668020-d9703041-Reviews-Beihai_Restaurant-Kalamata_Messenia_Region_Peloponnese.html" style={sMediaStyle}></a>&nbsp;
      </p>

      <p className="text-center">
        <Link id="menubtn" to="/menu" className="btn btn-outline">ΜΕΝΟΥ ΜΕ ΦΩΤΟΓΡΑΦΙΕΣ</Link>
      </p>
      <p className="text-center">
        <Link id="menubtn" className="btn btn-outline" onClick={()=>this.setState({show: true})}>ΚΡΑΤΗΣΗ</Link>
      </p>
      </div>
      <BookTable show={this.state.show} handleClose={this.handleClose} language={this.props.lan} />
      </div>
    );

    var intro_en = (
      <div id="intro" className="container-fluid">
      <div className="container" style={background}>
      <p className="text-center"><img src="img/intro/logo.png"></img></p>
      <h1 className="text-center"> Welcome </h1>
      <h3 className="text-center">The Chinese restaurant of Kalamata since 1991.</h3>
      <p className="text-center">Experience the special flavors of China through a journey of the senses. A magical journey to the culture and philosophy of one of the oldest countries in the world through its dishes. A meeting point; seduction of smell and flavour for the sole purpose of enjoying your palate.</p>
      <p className="text-center">Telephone: <a href="tel:2721093470" style={{'color': 'white'}}>27210 93470</a> | Mon-Sun: 19:15 - 00:30 | <a href="https://g.page/beihai-restaurant?share" style={{"color": "white"}}>Akrita 131</a>, Kalamata</p>

      <p className="text-center">
        <a className="fab fa-facebook-square" style={sMediaStyle} href="https://www.facebook.com/beihai.restaurant/"></a>&nbsp;
        <a className="fab fa-instagram-square" style={sMediaStyle} href="https://www.instagram.com/beihaigr/"></a>&nbsp;
        <a className="fab fa-tripadvisor" href="https://www.tripadvisor.co.uk/Restaurant_Review-g668020-d9703041-Reviews-Beihai_Restaurant-Kalamata_Messenia_Region_Peloponnese.html" style={sMediaStyle}></a>&nbsp;
      </p>

      <p className="text-center">
        <Link id="menubtn" to="/menu" className="btn btn-outline">MENU</Link>
      </p>
      <p className="text-center">
        <Link id="menubtn" className="btn btn-outline" onClick={()=>this.setState({show: true})}>RESERVE A TABLE</Link>
      </p>
      </div>
      <BookTable show={this.state.show} handleClose={this.handleClose} language={this.props.lan}/>
      </div>
    );

    if (this.props.lan === "gr") {
      return intro_gr;
    } else {
      return intro_en;
    }
  }
}
