import React from 'react';
import {Link} from 'react-router-dom';
import {LanguageContext} from './context'
import {logo} from './img/logo.png'

export class TopNav extends React.Component {
	constructor(props) {
			super(props);
	}

	static contextType = LanguageContext;

	handleClick = lan => event => {
			localStorage.setItem("lan", lan);
	}

	render() {

		var style = {"background": "red", "position": "relative", "top": "-10px", "left": "0px"};

		/*
		<Link to="/">
		<i class="fa fa-cutlery" aria-hidden="true" href="#"><span class="badge" style={style}>2</span></i>
		</Link>*/

		/*<ul class="navbar-nav mr-auto mx-auto">
			<Link href="tel:2721093470"> Tel: 27210 93470</Link>
		</ul>*/
		var topNav_gr = (

			<nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light" style={{"padding": "0.1rem 1rem"}}>
				<Link className='navbar-brand' to="/"><strong>Beihai Restaurant</strong></Link>

				<ul className="navbar-nav ms-auto">
					<li className="nav-item" style={{"paddingRight": "0.5rem"}}>
						<a className="nav-link" href="tel:2721093470"><i class="fa fa-phone-alt"></i> 27210 93470 </a>
		      </li>
				</ul>
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
		      <span class="navbar-toggler-icon"></span>
		    </button>

				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ms-auto">
						<li className="nav-item">
			        <a className="nav-link" href="https://g.page/beihai-restaurant?share">Τοποθεσία</a>
			      </li>
						<li className="nav-item">
			        <a className="nav-link" href={window.location.href} onClick={this.handleClick("en")}>English</a>
			      </li>
						<li className="nav-item">
							<a className="nav-link" href={window.location.href} onClick={this.handleClick("cn")}>中文</a>
						</li>
					</ul>
				</div>
			</nav>

		);


		var topNav_en = (

			<nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light" style={{"padding": "0.1rem 1rem"}}>
				<Link className='navbar-brand' to="/"><strong>Beihai Restaurant</strong></Link>

				<ul className="navbar-nav ms-auto">
					<li className="nav-item" style={{"paddingRight": "0.5rem"}}>
						<a className="nav-link" href="tel:2721093470"><i class="fa fa-phone-alt"></i> 27210 93470 </a>
					</li>
				</ul>


				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ms-auto">
						<li className="nav-item">
			        <a className="nav-link" href="https://g.page/beihai-restaurant?share">Location</a>
			      </li>
						<li className="nav-item">
			        <a className="nav-link" href={window.location.href} onClick={this.handleClick("gr")}>Ελληνικά</a>
			      </li>
						<li className="nav-item">
							<a className="nav-link" href={window.location.href} onClick={this.handleClick("cn")}>中文</a>
						</li>
					</ul>
				</div>
			</nav>

		);

		var topNav_cn = (

			<nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light" style={{"padding": "0.1rem 1rem"}}>
				<Link className='navbar-brand' to="/"><strong>Beihai Restaurant</strong></Link>

				<ul className="navbar-nav ms-auto">
					<li className="nav-item" style={{"paddingRight": "0.5rem"}}>
						<a className="nav-link" href="tel:2721093470"><i class="fa fa-phone-alt"></i> 27210 93470 </a>
					</li>
				</ul>


				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav ms-auto">
						<li className="nav-item">
							<a className="nav-link" href="https://g.page/beihai-restaurant?share">地点</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href={window.location.href} onClick={this.handleClick("gr")}>Ελληνικά</a>
						</li>
						<li className="nav-item">
							<a className="nav-link" href={window.location.href} onClick={this.handleClick("en")}>English</a>
						</li>
					</ul>
				</div>
			</nav>

		);

		if (this.context === "gr") {
			return topNav_gr;
		} else if (this.context === "cn") {
			return topNav_cn;
		} else {
			return topNav_en;
		}

	}
}

export class BottomNav extends React.Component {

	render() {
		var copywriteStyle = {'textAlign': 'right', 'fontSize': '0.8em'};
		var bottomBarStyle = {'padding': '1em 1em 1em 1em', 'color': '#FFF'};
		var iconStyle = {'color': 'white', 'fontSize': '24px'};

		var d = new Date();
		var year = d.getFullYear();

		var bottomNav = (
			<footer className='navbar-custom container-fluid'>
	      <div style={bottomBarStyle}>
	        <div className='row'>
	    		  <div className="col-sm-8">
	      			<a href="mailto:contact@beihai.gr" className="fa fa-envelope" style={iconStyle}></a>&nbsp;
	      			<a href="https://www.facebook.com/beihai.restaurant/" className="fab fa-facebook-square" style={iconStyle}></a>&nbsp;
	            <a href="https://www.instagram.com/beihaigr/" className="fab fa-instagram-square" style={iconStyle}></a>&nbsp;
	            <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g668020-d9703041-Reviews-Beihai_Restaurant-Kalamata_Messenia_Region_Peloponnese.html" className="fab fa-tripadvisor" style={iconStyle}></a>&nbsp;
	          </div>
	          <div className="col-sm-4">
	            <p style={copywriteStyle}>&copy; Beihai Restaurant {year} </p>
	          </div>
	        </div>
	      </div>
	    </footer>
		);

		return bottomNav;
	}
}
