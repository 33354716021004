import React, {useContext} from 'react';
import {Item} from './Item.js'
import {LanguageContext} from '../../context'
import { InView } from 'react-intersection-observer';

function Section(props) {
  let items = props.items;
  let columns = 3;

  let rowList = [];
  for (let i=0; i<items.length; i+=columns) {

    let citems = items.slice(i,i+columns)
    
    let columnList = citems.map( (item, index) => (
      <div className="col-md-4">
        <InView as="div" threshold={[1]} onChange={(inView, entry) => {
          if (inView && window.innerWidth <= 768) {
            props.onViewedCategoryChange(props.title)
          }
        }}>
          <Item key={item.id} code={item.id} header={item.name} price={item.price} description={item.description}/>
        </InView>

      </div>
    ));

    rowList.push(
      <div className="row justify-content-md-center">
        {columnList}
      </div>
    );
  }

  return (

    <div>
      <InView as="div" threshold={[0.55]} onChange={(inView, entry) => {
          if (inView) {
            //alert(props.title)
            props.onViewedCategoryChange(props.title)
          }
      }}>
        <h5 id={props.title.replaceAll(" ", "_")} className="text-center" style={{paddingTop: "55px", paddingBottom: "15px"}}>{props.title}</h5>
        {rowList}
      </InView>
      <hr></hr>
    </div>

  )
}

export function MainContent(props) {


	const language = useContext(LanguageContext);


  const items = props.items;

  var sectionList = []
  for (let sectionName in items) {

    let itemList = items[sectionName].map( x => (
      (language === "en")?
      {"id": x.id, "name": x.name_en, "price": x.price, "description": x.description_en} :
      (language === "cn")?
      {"id": x.id, "name": x.name_cn, "price": x.price, "description": x.name_cn} :
      {"id": x.id, "name": x.name_gr, "price": x.price, "description": x.description_gr}
    ));

    sectionList.push(
      <Section title={sectionName} items={itemList} onViewedCategoryChange={props.onViewedCategoryChange} viewCategory={props.viewCategory}/>
    );
  }

  return (

    <div className="col-9" style={{"borderLeft": "1px solid gray"}}>
      <div className="col-12">
        <br></br>
        <br></br>
        <h3 style={{'textAlign': 'center'}}>{(language === "en") ? "Menu" : (language === "en")? "菜单" : "Μενού"}</h3>
        <hr/>
      </div>
      {sectionList}
      <br></br>
    </div>
  );

}
