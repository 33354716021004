import React from 'react';


export class Cuisine extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    var styleName = {'paddingTop': '50px', 'paddingBottom': '50px', 'color': '#FFF'};

    var cuisine_gr = (
      <div className="row" style={styleName}>
        <div className="col-sm-6">
          <h2>Η Κουζίνα</h2>
          <p>Ένα σημείο συνάντησης και αποπλάνησης της όσφρησης και της γεύσης σας, με μοναδικό σκοπό την παράδοσή σας στην απόλαυση των εδεσμάτων μας.
          Αντιπροσωπευτικά δείγματα της ποιοτικής και υγιεινής μας κουζίνας με γνώμονα την υγεία και την ευεξία η αυθεντική κινέζικη κουζίνα χρησιμοποιεί μόνο εκλεκτά υλικά - πάντα φρέσκα. Πολλά από τα οποία έχουν ευεργετικές, καταπραϋντικές ιδιότητες. Ελάτε να γευτείτε τα πιάτα στο μονάδικο κινέζικο εστιατόριο της πόλης που εδώ πάνω από τριάντα χρόνια αγαπήσατε.
          </p>
        </div>
        <div className="col-sm-6">
          <img className="img-responsive center-block" src={"img/intro/cuisine/1.jpg"} width='100%'></img>
        </div>
      </div>
    );

    var cuisine_en = (
      <div className="row" style={styleName}>
        <div className="col-sm-6">
          <h2>Our Cuisine</h2>
          <p>Representative samples of our high quality and healthy authentic Chinese cuisine use only the finest of all ingredients - always fresh. Many of which have beneficial, soothing properties. Come to taste the dishes in the only Chinese restaurant in town that is for thirty years loved by its customer.</p>
        </div>
        <div className="col-sm-6">
          <img className="img-responsive center-block" src={"img/intro/cuisine/1.jpg"} width='100%'></img>
        </div>
      </div>
    );

    if (this.props.lan === "gr") {
      return cuisine_gr;
    } else {
      return cuisine_en;
    }
  }
}

export class DescriptionPage1 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    var guyBackground = {'height': '50vh', 'backgroundImage': 'url(img/intro/guy.jpg)', 'backgroundSize': 'cover', 'backgroundPosition': 'top left, bottom center', 'backgroundRepeat': 'noRepeat'};
    var souplabelBackground = {'height': '50vh', 'backgroundImage': 'url(img/intro/soup.jpg)', 'backgroundSize': 'cover', 'backgroundPosition': 'top left, bottom center', 'backgroundRepeat': 'noRepeat'};
    var souplabel = {'textAlign': 'center', 'backgroundColor': 'rgba(0,0,0,0.6)', 'width': '50%'};
    var souplabelp = {'color': '#FFF', 'paddingTop': '20px', 'paddingBottom': '20px', 'paddingLeft': '5px', 'paddingRight': '5px'};

    var appetiserDiv = {'height': '50vh', 'paddingTop': '50px', 'color': '#FFF'};
    var saladDiv = {'paddingTop': '50px', 'color': '#FFF'};
    var soupDiv = {'paddingTop': '50px', 'paddingLeft': '15px', 'paddingRight': '15px', 'textAlign': 'center', 'color': '#FFF'};
    var rightSideDiv = {'minHeight':'100vh', 'paddingLeft': '0px', 'paddingRight': '0px', 'paddingTop': '0px', 'paddingBottom': '0px'};

    var description_gr = (
    <div className="row">
      <div id="redSidePanel" className="col-sm-6">
        <div style={appetiserDiv}>
          <h2><strong>Ορεκτικά</strong></h2>
          <i id="dot" className="fa fa-circle"></i>
          <p>Τα ορεκτικά αποτελούν σημαντικό στάδιο στο κινέζικο τραπέζι. Ποικίλουν στην γεύση, την παρασκευή ή τα υλικά με ένα μόνο κοινό χαρακτηριστικό. Τρώγονται σε μικρές μπουκιές ή ατομικά τεμάχια και συνήθως είναι περίτεχνα παρασκευασμένα για να σας ‘ανοίγουν’ την όρεξη. Συνιστούν την κλασσική επιλογή σε ένα γεύμα τσάι. Στον κατάλογό μας θα βρείτε τα πιο αντιπροσωπευτικά από αυτά.</p>
        </div>
        <div style={saladDiv}>
          <h2><strong>Σαλάτες</strong></h2>
          <i id="dot" className="fa fa-circle"></i>
          <p>Σήμα κατατεθέν τους η πολυχρωμία, η αρμονική ανάμειξη των υφών και η υπέρμετρη προσοχή στο σχήμα των λαχανικών για ένα αισθητικό αποτέλεσμα. Δεν είναι παράξενο λοιπόν που κατέχουν πολύ σημαντικό ρόλο στην διατροφική κουλτούρα της Κίνας. Συνήθως τα λαχανικά αρωματίζονται με λάδι φιστικιών ή σησαμέλαιο και απλώς ζεματίζονται έτσι ώστε να διατηρήσουν την ζωντάνιά τους. Η λεπτή και ελαφριά τους γεύση βοηθά στην καλύτερη ανάδειξη των κρεατικών που συνοδεύουν το γεύμα. </p>
        </div>
      </div>
      <div className="col-sm-6" style={rightSideDiv}>
        <div style={guyBackground}>
          <div style={soupDiv}>
            <h2><strong>Σούπες</strong></h2>
            <i id="dot" className="fa fa-circle"></i>
            <p>Οι κινέζοι λατρεύουν τις σούπες. Τις θεωρούν ιδανική επιλογή στα γεύματά τους ανεξαρτήτου εποχής. Για αυτό μην εκπλαγείτε αν μες στο κατακαλόκαιρο δείτε κάποιον κινέζο να τρώει καυτή σούπα. Στο τραπέζι υπάρχει πάντα σούπα κι αυτό γιατί πιστεύεται πως βοηθούν στο να ‘καθαρίζουν’ την επίγευση από προηγούμενο πιάτο και προετοιμάζει τον ουρανίσκο να δεχτεί την επόμενη γεύση. Αυτός είναι κ ο λόγος που η παρουσία της σούπας στο τραπέζι διαρκεί καθ’όλο το γεύμα.</p>
          </div>
        </div>
        <div style={souplabelBackground}>
          <div style={souplabel}>
            <p style={souplabelp}>Σούπα καβουριού.</p>
          </div>
        </div>
      </div>
    </div>
    );

    var description_en = (
    <div className="row">
      <div id="redSidePanel" className="col-sm-6">
        <div style={appetiserDiv}>
          <h2><strong>Appetizers</strong></h2>
          <i id="dot" className="fa fa-circle"></i>
          <p>Appetizers are an important course in the Chinese table. They vary in taste, in preparation and ingredients with only one thing in common; Appetizers are eaten in small bites or individual portion pieces and usually elaborately prepared for you to "open" your appetite. They are a standard choice for a tea meal. In our menu you will find the most representative of them.</p>
        </div>
        <div style={saladDiv}>
          <h2><strong>Chinese Salads</strong></h2>
          <i id="dot" className="fa fa-circle"></i>
          <p>Salads in China are famous for their colours, the mix of textures and attention to detailed cuts of the vegetables creates a beautiful and flavorful dish. It is a major nutritional aspect of Chinese cuisine; Vegetables are usually aromatised with sesame oil and are just satted to maintain their texture and lifeliness when serving. Contrasting the western culinary philosophy, Chinese salads cannot be the main dish as well as they are usually served warmο. The light and refreshing flavour of salads is an excellent accompaniment of meat and fish dishes and helps in the emergence of their flavours in your palate. </p>
        </div>
      </div>
      <div className="col-sm-6" style={rightSideDiv}>
        <div style={guyBackground}>
          <div style={soupDiv}>
            <h2><strong>Soups</strong></h2>
            <i id="dot" className="fa fa-circle"></i>
            <p>If you prefer savory flavors, try the spicy sour. A soup that was prefered by Mao Tse-tung in his dinners.</p>
          </div>
        </div>
        <div style={souplabelBackground}>
          <div style={souplabel}>
            <p style={souplabelp}>Crab soup</p>
          </div>
        </div>
      </div>
    </div>
    );

    if (this.props.lan === "gr") {
      return description_gr;
    } else {
      return description_en;
    }
  }
}

export class DescriptionPage2 extends React.Component {

  render() {

    var styleRightPanel = {'paddingTop': '50px', 'minHeight': '100vh', 'color': '#FFF'};
    var styleLeftPanel = {'minHeight': '100vh', 'paddingLeft': '0px', 'paddingRight': '0px', 'paddingTop': '0px', 'paddingBottom': '0px', 'color': '#FFF'};
    var label = {'backgroundColor': 'rgba(0,0,0,0.6)', 'width': '50%'};
    var labelp = {'paddingTop': '20px', 'paddingBottom': '20px', 'paddingLeft': '5px', 'paddingRight': '5px'};
    var curryBackground = {'height': '50vh', 'backgroundImage': 'url(img/intro/curry.jpg)', 'backgroundSize': 'cover', 'backgroundPosition': 'top left, bottom center', 'backgroundRepeat': 'no-repeat'};
    var shrimpBackground = {'height': '50vh', 'backgroundImage': 'url(img/intro/shrimps.jpg)', 'backgroundSize': 'cover', 'backgroundPosition': 'top left, bottom center', 'backgroundRepeat': 'no-repeat'};

    var description_gr = (
      <div className="row">

        <div className="col-sm-6" style={styleLeftPanel}>
					<div style={shrimpBackground}>
						<div style={label}>
							<p style={labelp}>Γαρίδες με μανιτάρια και λαχανικά.</p>
						</div>
					</div>
					<div style={curryBackground}>
						<div style={label}>
							<p style={labelp}>Κοτόπουλο κάρυ σοταρισμένα με μπρόκολο.</p>
						</div>
        	</div>
        </div>

    		<div id="greenSidePanel" className="col-sm-6">
          <div style={styleRightPanel}>
						<h2><strong>Νούντλς</strong></h2>
						<i id="dot" className="fa fa-circle"></i>
						<p>Υπάρχουν τόσες παραλλαγές αυτού του ζυμαρικού στο σχήμα, στο μέγεθος και το χρώμα ικανές να μπερδέψουν. Κυρίως όμως κατατάσσονται σε δύο τύπους. Εκείνα που έχουν ως βάση το ρυζάλευρο και λέγονται mifen και εκείνα με βάση το αυγό και το σιτάρι. Chow mein σημαίνει επί λεξεί ‘τηγανισμένα μακαρόνια’. Σοτάρονται στο “wok” συνοδεία λαχανικών και μπορείτε να προσθέσετε κοτόπουλο, μοσχάρι ή χοιρινό κατά τις προτιμήσεις σας. Τα ‘ρυζομακάρονα’ είναι η ιδανική επιλογή για ένα ελαφρύ πιάτο γιατί έχει ελάχιστα έως καθόλου λιπαρά ή λάδι. Ταιριάζουν εξαιρετικά με γαρίδες. Φυσικά έχετε την επιλογή του μοσχαριού, κοτόπουλου ή χοιρινού.</p>
            <h2><strong>Ρύζι</strong></h2>
  					<i id="dot" className="fa fa-circle"></i>
  	        <p>Το τηγανιτό ρύζι όπως –άλλωστε- και τα noodles είναι από τα πιο αρχαία κινέζικα πιάτα με αναφορές που χάνονται στον χρόνο. Οι παραλλαγές αμέτρητες, αναλόγως τα υλικά που χρησιμοποιούνται. Σήμερα πλέον, ακόμα και σε επίσημα δείπνα δεν νοείται η απουσία του ρυζιού αυτού από το τραπέζι.</p>
			    </div>
        </div>

      </div>
    );

    var description_en = (
      <div className="row">

        <div className="col-sm-6" style={styleLeftPanel}>
					<div style={shrimpBackground}>
						<div style={label}>
							<p style={labelp}>Shrimps with vegetables and mushrooms.</p>
						</div>
					</div>
					<div style={curryBackground}>
						<div style={label}>
							<p style={labelp}>Chicken curry sautted with brocoli vegetables.</p>
						</div>
        	</div>
        </div>

    		<div id="greenSidePanel" className="col-sm-6">
          <div style={styleRightPanel}>
						<h2><strong>Beihai Noodles</strong></h2>
						<i id="dot" className="fa fa-circle"></i>
						<p>In China there are alot of variation of noodles; in terms of shape, colour but also wheat. But there are two main variations, those that are made of rice flour named 'mifen' and those made from egg and wheat known as 'chow mein'. They are sated with various meats, seafood or vegetables depending on your choice.</p>
            <h2><strong>Rice</strong></h2>
  					<i id="dot" className="fa fa-circle"></i>
  	        <p>Rice similarly to noodles are the most ancient dishes of asia; The flavors are limitless, today even at formal dinners it is essential to have rice on the Chinese table. </p>
			    </div>
        </div>

      </div>
    );

    if (this.props.lan === "gr") {
      return description_gr;
    } else {
      return description_en;
    }
  }
}

export class Ingredients extends React.Component {

  constructor (props) {
    super(props);
  }

  render () {
    var styleName = {'color': '#FFF', 'paddingTop': '100px', 'paddingBottom': '100px'}

    var ingredients_gr = (
      <div className="row" style={styleName}>

        <div className="col-sm-6">
          <h2>Τα Υλικά</h2>
          <p>Στην Κίνα είναι σε όλους γνωστό ότι η μαγειρική δεν ξεκινάει στη κουζίνα. Αρχίζει με μια βόλτα στην αγορά άλλωστε για αυτό είναι συνηθισμένο να κατακλύζονται οι αγορές τα πρωινά στην Γκουανγκξί. Οι νοικοκυρές βγαίνουν σε αναζήτηση των λαχανικών για να παρασκευάσουν τα παραδοσιακά εδέσματα.</p>
          <p>Πιστοί κ εμείς στην ιεροτελεστία αυτή φροντίζουμε για την ποιότητα κ την φρεσκάδα των υλικών πολλά από τα οποία είναι άγνωστα στην δύση. Η κουζίνα της πόλης Μπέιχάι είναι από τις πιο ποικιλόμορφες κ τις πιο εκλεπτυσμένες στην κίνα, λόγω των επιρροών από διπλανές επαρχίες και γειτονικές χώρες καθώς και την πληθώρα των υλικών που κατέφθανε μέσω του εμπορίου.</p>
        </div>

        <div className="col-sm-6">
          <div className="carousel slide" data-ride="carousel" >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="responsive center-block" src="img/intro/ingredients/1.jpg" width='100%'/>
              </div>
              <div className="carousel-item">
                <img className="responsive center-block" src="img/intro/ingredients/2.jpg" width='100%'/>
              </div>
              <div className="carousel-item">
                <img className="responsive center-block" src="img/intro/ingredients/3.jpg" width='100%'/>
              </div>
            </div>
          </div>
        </div>

      </div>
    );

    var ingredients_en = (
      <div className="row" style={styleName}>

        <div className="col-sm-6">
          <h2>Ingredients</h2>
          <p>In China everyone knows that cooking does not begin in the kitchen. It begins with a trip to the market, Beihai cuisine is one of the most varied and among the most refined in China, due to the fact that has been influenced by other countries. The attraction of the dishes lies in the fact that, thanks to the skills of the Cantonese cooks, the individual aromas of the ingredients can always be clearly distinguished. </p>
        </div>

        <div className="col-sm-6">
          <div className="carousel slide" data-ride="carousel" >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="responsive center-block" src="img/intro/ingredients/1.jpg" width='100%'/>
              </div>
              <div className="carousel-item">
                <img className="responsive center-block" src="img/intro/ingredients/2.jpg" width='100%'/>
              </div>
              <div className="carousel-item">
                <img className="responsive center-block" src="img/ingredients/3.jpg" width='100%'/>
              </div>
            </div>
          </div>
        </div>

      </div>
    );

    if (this.props.lan === "gr") {
      return ingredients_gr;
    } else {
      return ingredients_en;
    }
  }
}

export class RestaurantDescription extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      var background = {"backgroundColor": "#ff6347"};
      return (
        <div className="container-fluid" style={background}>
          <Cuisine lan={this.props.lan} />
          <DescriptionPage1 lan={this.props.lan} />
          <DescriptionPage2 lan={this.props.lan} />
          <Ingredients lan={this.props.lan} />
    		</div>
      );
    };
}
