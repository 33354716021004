import React from 'react';
import MenuData from './data/menu.json';
import {LanguageContext} from './context'

class SectionHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var section = (
      <h5 className="text-center">{this.props.title}</h5>
    );

    return section;
  }
}

class Item extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var item = (
      <div>
        <div className="clearfix">
          <h6 className="float-start"><sub>{this.props.code}</sub> {this.props.header} </h6><h6 className="float-end"> <b>{this.props.price} €</b> </h6>
        </div>
        <p><i>{this.props.description}</i></p>
      </div>
    );

    return item;
  }
}

export class Menu extends React.Component {
  constructor(props) {
    super(props);
  }

	static contextType = LanguageContext;

  render() {
    var items = {};

    var pivot = MenuData[0].category_gr;

    if (this.context === "en") {
      pivot = MenuData[0].category_en;
    } else if (this.context === "cn") {
      pivot = MenuData[0].category_cn;
    }

    items[pivot] = [];
    items[pivot].push(MenuData[0]);

    for (var i = 1; i < MenuData.length; i++) {
      var category = MenuData[i].category_gr

      if (this.context === "en") {
        category = MenuData[i].category_en;
      } else if (this.context === "cn") {
        category = MenuData[i].category_cn;
      }

      if (pivot === category) {
        items[pivot].push(MenuData[i]);
      } else {
        items[category] = [];
        items[category].push(MenuData[i]);
        pivot = category;
      }
    };

    var itemList = []
    for (var key in items) {
      itemList.push(<SectionHeader key={key} title={key}/>);
      itemList.push(<br></br>);

      for (var j=0; j<items[key].length; j++) {
        var item = items[key][j];
        if (this.context === "en") {
          itemList.push(<Item key={item.id} code={item.id} header={item.name_en} price={item.price} description={item.description_en}/>);
        } else if (this.context === "cn") {
          itemList.push(<Item key={item.id} code={item.id} header={item.name_cn} price={item.price} description={item.description_cn}/>);
        } else {
          itemList.push(<Item key={item.id} code={item.id} header={item.name_gr} price={item.price} description={item.description_gr}/>);
        }
      }
      itemList.push(<br></br>);
    }

    var center = {'textAlign': 'center'};

    var menu_gr = (
      <div className="container">
        <br></br>
        <h3 style={center}>Μενού</h3>
        <hr/>
        <br></br>
        {itemList}
        <br></br>
      </div>
    );

    var menu_en = (
      <div className="container">
        <br></br>
        <h3 style={center}>Menu</h3>
        <hr/>
        <br></br>
        {itemList}
        <br></br>
      </div>
    );

    var menu_cn = (
      <div className="container">
        <br></br>
        <h3 style={center}>菜单</h3>
        <hr/>
        <br></br>
        {itemList}
        <br></br>
      </div>
    );

    if (this.context === "en") {
      return menu_en;
    } else if(this.context === "cn") {
      return menu_cn;
    } else {
      return menu_gr;
    }
  }
}
