import React, {useContext} from 'react';
//import './App.css';
//import './custom.scss';
import {Menu} from './menu';
import {Menu_visual} from './menu_visual/menu_visual';
import {Intro} from './intro';
import {RestaurantDescription} from './cuisine';
import {TopNav, BottomNav} from './navbar';
import {LanguageContext} from './context';

import { Route, BrowserRouter, Redirect } from 'react-router-dom'

export function Restaurant(props) {

  const language = useContext(LanguageContext);

  return (
    <div className="Restaurant">
      <Intro lan={language} />
      <RestaurantDescription lan={language} />
    </div>
  );
}

export class App extends React.Component {
  constructor(props) {
      super(props);

      if (localStorage.getItem("lan") === null) {
        localStorage.setItem("lan", "gr");
        this.state = {lan: "gr"};
      } else {
        this.state = {lan: localStorage.getItem("lan")};
      }
  }

  //<Route exact path="/menu+" component={Menu_visual}/>
  render() {
    const router = (
      <BrowserRouter>
        <LanguageContext.Provider value={this.state.lan}>
          <TopNav/>
          <Route exact path="/" component={Restaurant}/>
          <Route exact path="/menu" component={Menu_visual}/>

          <BottomNav/>
        </LanguageContext.Provider>
      </BrowserRouter>
    );

    return router;
  }
}
