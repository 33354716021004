import React from 'react';
import ScrollIntoView from 'react-scroll-into-view'
import FA from 'react-fontawesome'
import { Button } from 'react-bootstrap';

export class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {infoClicked : false};
  }

  render() {

    let file = "/img/menu/" + this.props.code + "/1.jpeg";
    let na_en = "/img/menu/na/en.jpeg";
    let na_gr = "/img/menu/na/gr.jpeg";
    let na_cn = "/img/menu/na/gr.jpeg";
    let na;

    let addString;
    if (this.context === "en") {
      na = na_en;
      addString = "add";
    } else if (this.context === "cn") {
      na = na_cn;
      addString = "加";
    } else {
      na = na_gr;
      addString = "Προσθήκη";
    }

    let picture;
    if (this.state.infoClicked === true) {
      picture = (
        <div style={{position: "relative"}}>
          <img src={file} className="img-fluid" style={{opacity: "1", filter: "blur(8px) brightness(70%)", borderStyle: "solid", borderWidth: "2px", borderColor: "black", width: "100%"}} onError={(e) => e.target.src=na}></img>
          <p style={{fontSize: "16px", color: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center"}}><i>{this.props.description}</i></p>
          <FA onClick={() => this.setState({infoClicked:false})} name="times-circle" size="2x" style={{ color: "white", cursor: "pointer", position: "absolute", bottom:5, right:5}}/>
        </div>
      );
    } else {
      picture = (
       <div style={{position: "relative"}}>
         <img src={file} className="img-fluid" style={{borderStyle: "solid", borderWidth: "2px", borderColor: "black", width: "100%"}} onError={(e) => e.target.src=na}></img>
         <FA onClick={() => this.setState({infoClicked:true})} name="info-circle" size="2x" style={{ color: "white", cursor: "pointer", position: "absolute", bottom:5, right:5}}/>
       </div>
     );
    }

    var item = (
      <div>
        <div class="row">
          <div class="col-12">
            {picture}
            <hr></hr>
          </div>
        </div>
        <div class="row">
          <div class="col-9">
            <h6 className="float-start"><sub>{this.props.code}</sub> {this.props.header} </h6>
          </div>
          <div class="col-3">
            <h6 className="float-end"> <b>{this.props.price}€</b> </h6>
          </div>
          <br></br>
        </div>
        <div class="row">
          <div class="col-12">

            <br></br>
            <br></br>
          </div>
        </div>

      </div>
    );
            //<button type="button" class="btn btn-outline-secondary">{addString}</button>
    return item;
  }
}
